import './button-wc.js';
class MainMenuWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			.top-bar{
				position: fixed;
				width:100%;
				z-index: 2;
				top:0;
				left:0;
			}

			.top-bar__logo-img{
				max-width: 10em;
				aspect-ratio: 160 / 63;
			}
			@media screen and (max-width: 35.625em){
				.top-bar__logo-img{
					max-width: 5em;
				}
			}

			.top-bar__content{
				background-color: var(--secondary-color);
				padding: var(--padding-page);
				display:flex;
				justify-content: space-between;
				align-items: center;
			}

			.top-bar__bg-image{
				background-image: url('assets/img/separator.svg');
				background-size: 10.129em 1.73em;
				width: 100%; 
				height: 1.73em;
				margin-top:-0.79em;

				content:"";
			}

			.top-bar__nav{
				display: flex;
				gap:1em;
			}
		`;
	}
	connectedCallback(){
		this.baseURL = document.baseURI.split(window.location.host)[1];
		this.render();
		
		
	}
	render() {
		const isHomepage = window.location.pathname == this.baseURL;
		// console.log('is home: ', isHomepage);
		// console.log('document.baseURI: ', document.baseURI);
		// console.log('base url: ',this.baseURL);
		this.shadowRoot.innerHTML = /* html */`
			<style>${MainMenuWC.styles}</style>
			
			<header class="top-bar aria-page-hide">
				<div class="top-bar__content">

					<img src="assets/img/sugarpacket-logo.svg" width="160" height="63" alt="SugarPacket Logo" class="top-bar__logo-img">

					<nav class="top-bar__nav">
						${isHomepage == true ? '' : `<button-wc icon="home" text="Homepage" color="primary" size="md" action="${this.baseURL}"></button-wc>`}
						<button-wc icon="bmac" text="Buy Me A Coffee" color="primary" size="md" action="https://www.buymeacoffee.com/merchedev"></button-wc>
						<button-wc icon="mail" text="Contact me" color="primary" size="md" action="mailto:hello@sugarpacket.online"></button-wc>
						<button-wc icon="question-mark" text="About" color="primary" size="md" action="open-about-modal"></button-wc>
					</nav>
				</div>
				<div class="top-bar__bg-image"></div>
			</header>
		`;
	}
}
customElements.define('main-menu-wc', MainMenuWC);