import '../components/main-menu-wc.js';
import '../components/footer-wc.js';
import '../components/big-title-wc.js';
import '../components/title-wc.js';
import { errorImgEndpoint } from '../services/data-service.js';
class ErrorView extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
		.section{
				padding: 8em var(--padding-page);
				display:flex;
				justify-content: center;
				align-items: center;
				overflow:hidden;
				box-sizing:border-box;
			}

			.section--top{
				padding: 18em var(--padding-page);
			}
			@media screen and (max-width: 61.875em){
				.section{
					padding: 6em var(--padding-page);
				}
			}	
		
			@media screen and (max-width: 61.875em){
				.section--top{
					padding: 12em var(--padding-page);
				}
			}
				
			.section__content{
				width:100%;
				max-width: 65.625em;
				display:flex;
				gap: 4em;
				align-items: center;
				justify-content: center;
			}

			@media screen and (max-width: 61.875em){
				.section__content{
					flex-direction: column;
				}
			}

			.text-section{
				max-width: 34em;
			}

			.text-section__p{
				margin: 1.25em 0;
				line-height:1.35em;
				font-family: var(--font);
				font-size:1.3em;
			}
			.text-section__link{
				display:inline;
			}

			.error-quote{
				width:100%;
				max-width:24em;
				border-radius: 1.5em;
				overflow:hidden;
				aspect-ratio: 1 / 1;
				height: auto;
				box-shadow: var(--hard-shadow);
			}
			@media screen and (max-width: 61.875em){
				.error-quote{
						order:2;
				}
			}
		`;
	}
	connectedCallback(){
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${ErrorView.styles}</style>
			<main-menu-wc></main-menu-wc>
			<main class="section section--top">
			<div class="section__content">
				<img fetchpriority="high" width="1500" height="844" src="${errorImgEndpoint}" class="error-quote"/>
				<div class="section__text text-section">
					<big-title-wc text="404 - Not Found" color="secondary"></big-title-wc>
					<title-wc text="Oops! This is awkward." color="secondary"></title-wc>
					<p class="text-section__p">I can’t find the page you’re looking for. Who knows what happened to it? Maybe it never existed.
					</p>
					<p class="text-section__p">Anyway, here’s an extra quote as a gift so you can - pretty, pretty please with SugarPacket on top - forgive me.</p>
				</div>
			</div>
			
		</main>
			<footer-wc></footer-wc>
		`;
	}
}
customElements.define('error-view', ErrorView);