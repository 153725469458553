import { getNewEl } from '../services/ui-service';

//? cambiado dialog a div normal para controlar el z-index y no tener problema por ejemplo con las alertas de error que se mostraban por debajo.
class ModalWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			:host{
				position:fixed;
				z-index:3;
				width:100vw;
				height:100vh;
				top:0;
				left:0;
				display:flex;
				justify-content: center;
				align-items: center;
				visibility:hidden;
				box-sizing: border-box;
			}

			:host(.show){
				visibility:visible;
			}
			
		
			.modal{
				background-color: var(--secondary-color);
				box-shadow: var(--hard-shadow);
				border-radius: 1.5em;
				padding:3em 5em;
				width: 101.25em;
				max-width: 85vw;
				height: 54.375em;
				max-height: 85vh;
				position:relative;
				box-sizing: border-box;
				transform: translateY(-100vh);
				transition: transform 0.3s ease-out;

			}
			.modal--show{
				transform: translateY(0);
			}
				@media screen and (max-width:44.375em){
					.modal{
						padding:1em;
						padding-top: 4.5em;
						max-width: 90vw;

					}
				}

			.content{
				width: 100%;
				height: 100%;
				max-height: 100%;
				overflow-y: scroll;
				display: grid; 
				grid-template-columns: repeat(2, 1fr); 
				grid-template-rows: repeat(4, 1fr); 
				grid-column-gap: 3em;
				grid-row-gap: 2em; 
				padding: 0.3em;
			}

			big-title-wc { grid-area: 1 / 1 / 1 / 1; } 
			.modal__column--a { grid-area: 2 / 1 / 3 / 2; } 
			.modal__column--b { grid-area: 3 / 1 / 4 / 2; } 
			.modal__column--c { grid-area: 4 / 1 / 5 / 2; } 
			.modal__column--d { grid-area: 2 / 2 / 3 / 3; } 
			.modal__column--e { grid-area: 3 / 2 / 5 / 3; } 
				
			@media screen and (max-width: 77.5em){
				.content{
					display:flex;
					flex-direction: column;
				}
			}

			.bg{
				background-color: rgba(3, 2, 3, 0.5);

				width:100vw;
				height:100vh;
				top:0;
				left:0;
				position:fixed;
				opacity:0;
				transition: opacity 0.1s linear;
		
			}

			.bg--show{
				opacity:1;		
			}

			.video{
				aspect-ratio: 16/9;
				width: 100%;
				border: none;
    		border-radius: 1em;
				box-shadow: var(--primary-shadow);
			}

			button-wc{
				position:absolute;
				top: 1em;
				right: 1em;
			}

			.text-section{
				max-width: 37em;
			}

			.text-section__p{
				margin: 1.25em 0;
				line-height:1.35em;
				font-family: var(--font);
			}

			
		`;
	}
	connectedCallback(){
		window.addEventListener('show-modal', this.showModal);
		window.addEventListener('close-modal', this.closeModal);
		window.addEventListener('button-clicked', this.handleButtonClicked);
	}
	render(modalContent) {
		this.shadowRoot.innerHTML = /* html */`
			<style>${ModalWC.styles}</style>
		
				<div class="bg"></div>
				<div class="modal">
				
					<div class="content">
						${modalContent}
					</div>
					<button-wc icon="x" text="Close modal" color="primary" size="md" action="close-modal"></button-wc>
				</div>

		`;
	}

	handleButtonClicked = (e) => {
		const action = e.detail.action;
		if(action == 'close-modal') this.closeModal();
	};

	showModal = (e) =>{
		let dialogContent          = e.detail.content;
		const cancelDeleteActionName = e.detail.cancel_action;
		const confirmDeleteActionName = e.detail.confirm_action;

		if(cancelDeleteActionName || confirmDeleteActionName) {
			const buttonsWrapperEl = getNewEl('div', { class: 'buttons'});
			if(cancelDeleteActionName) buttonsWrapperEl.appendChild(getNewEl('button-wc', { icon: 'cancel', text: 'Cancel', color: 'primary', size: 'md', action: cancelDeleteActionName}));
			
			if(confirmDeleteActionName) buttonsWrapperEl.appendChild(getNewEl('button-wc', { icon: 'check', text: 'Ok', color: 'primary', size: 'md', action: confirmDeleteActionName}));

			dialogContent += buttonsWrapperEl.outerHTML;
			
		} 

		this.render(dialogContent);
		// if(!cancelDeleteActionName && !confirmDeleteActionName) this.shadowRoot.querySelector('.modal').style.padding = '3.125em 1em 1em 1em';
		this.classList.add('show');
		setTimeout(() => {
			this.shadowRoot.querySelector('.modal').classList.add('modal--show');
			
			this.shadowRoot.querySelector('.bg').classList.add('bg--show');
		}, 50);
	};

	closeModal = () =>{
		
		this.shadowRoot.querySelector('.modal').classList.remove('modal--show');
		this.shadowRoot.querySelector('.bg').classList.remove('bg--show');
		setTimeout(() => {
			this.classList.remove('show');
		}, 300);
	};
}
customElements.define('modal-wc', ModalWC);