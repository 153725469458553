import './button-wc.js';
import './tooltip-wc.js';
import './text-link-wc.js';
class FooterWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
		.tooltip-button-container{
				position:relative;
			}
		.footer{
			display:flex;
			justify-content: center;
			font-family: var(--titles-font);
			align-items: center;
		}
		

		.footer-text{
			font-family: var(--titles-font);
			font-size:1.125em;
			display:flex;
			align-items: center;
		}
		button-wc{
			margin: 0 0.5em;
		}
		`;
	}
	connectedCallback(){
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${FooterWC.styles}</style>
			<footer class="footer aria-page-hide">
					<p class="footer-text">Made with </p>
					
						<div class="tooltip-button-container">
							<button-wc icon="question-mark" text="More info" color="secondary" size="sm" action="tooltip-made-with"></button-wc>
							<tooltip-wc tooltip-name="tooltip-made-with" bg-color="secondary"></tooltip-wc>
						</div>
						
							by <text-link-wc text="MercheDev" target="_blank" link="https://linktr.ee/merchedev"></text-link-wc>
				</footer>
		`;
	}
}
customElements.define('footer-wc', FooterWC);