const { getTooltipContent } = require('../services/ui-service');

class TooltipWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	get styles() {
		return /* css */ `
			:host{
				--width: 15em;
				--translate-x: 0%;
				position:absolute;
				bottom: 3.5em;
				left: calc(50% - (var(--width)/2));
				background-color: var(--${this.bgColor}-color);
				box-shadow: var(--hard-shadow);
				width:var(--width);
				max-width:var(--width);
				pointer-events:none;
				border-radius:1.5em;
				padding:1.2em 1em;
				opacity:0;
				transform: translateY(-10em);
				transition: transform 0.2s ease, opacity 0.1s 0.1s ease;
				display:block;
				box-sizing:border-box;
			}
			.tooltip__arrow {
				position: absolute;
				display: block;    
				width: 0px;        
				left: 50%;
				bottom: .35em;
				border: .9375em solid transparent;
				border-bottom: 0;
				border-top: .9375em solid var(--${this.bgColor}-color);
				transform: translate(-50%, calc(100% + .3125em));
				filter: drop-shadow(var(--hard-shadow));
			}

			.tooltip__list{
				text-align:left;
				list-style-type: none;
				margin-block-start: 0;
				margin-block-end: 0;
				padding-inline-start: 0;
			}

			.tooltip__el{
				font-family: var(--font);
				font-size: 0.8em;
				line-height: 1.2em;
				margin: 1em;
				position:relative;
				padding-left: 0.3em;
				list-style-type: none;
			}
			.bullet-icon{
				width: .9rem;
				height: .9rem;
				position:absolute;
				top:0.1em;
				left: -.9em;
				fill: var(--primary-color);
			}
				

			.tooltip__icon{
				width: 2em;
				height:auto;
				display:inline;
				margin-left: 0.5em;
				position:absolute;
				top:calc(50% - 1em);
			}

			:host(.sm){
				--width: auto;
				--translate-x: -50%;
				z-index:2;
				font-family: var(--font);
				white-space: nowrap;
				font-size: 0.8em;
				padding: 0.6em 1em;
				text-align:center;
				border-radius:0.8em;
				left: 50%;
				transform: translateY(-5em) translateX(var(--translate-x));
			}

			:host(.tooltip--show){
				opacity:1;
				transform: translateY(0) translateX(var(--translate-x));
			}
		`;
	}
	connectedCallback(){
		this.tooltipName = this.getAttribute('tooltip-name');
		this.tooltipContent = this.getAttribute('tooltip-content') || getTooltipContent(this.tooltipName);
		this.bgColor = this.getAttribute('bg-color');
		this.size = this.getAttribute('size');
		// this.forLabel = this.getAttribute('for-label');
		if(this.size == 'sm') this.classList.add('sm');
		this.render();

		window.addEventListener('show-tooltip', this.showTooltip);
		window.addEventListener('hide-tooltip', this.hideTooltip);
		
	}
	render() {
		// const isLabel = this.forLabel && this.forLabel != '';
		this.shadowRoot.innerHTML = /* html */`
			<style>${this.styles}</style>
			${this.tooltipContent}
			<div class="tooltip__arrow"></div>
		`;
	}
	showTooltip = (e) =>{
		const tooltipName    = e.detail['tooltip-name'];
		if(tooltipName == this.tooltipName){
			this.classList.add('tooltip--show');
		}
		
	};

	hideTooltip = (e) =>{
		const tooltipName    = e.detail['tooltip-name'];
		if(tooltipName == this.tooltipName){
			this.classList.remove('tooltip--show');
		}
	};
}
customElements.define('tooltip-wc', TooltipWC);