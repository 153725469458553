class BigTitleWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	get styles() {
		return /* css */ `
			.big-title{
					--border-line: 3px solid var(--black-color);
					--padding: 0.2em;
					display:inline-block;
					font-size: 3em;
					color: var(--${this.color}-color);
					text-shadow:  3px 3px 0 var(--black-color),
												-3px -3px 0 var(--black-color),
												3px -3px 0 var(--black-color),
												-3px 3px 0 var(--black-color),
												-3px 0 0 var(--black-color),
												3px 0 0 var(--black-color),
												0 -3px 0 var(--black-color),
												0 3px 0 var(--black-color);

					border-right: var(--border-line);
					position:relative;
					padding-right:var(--padding);
					margin-bottom: 0.5em;
					margin-top: 0.2em;
					margin-left: 0.2em;
					font-family: var(--font);
					font-weight: inherit;
					white-space: nowrap;
				}

				.big-title::before{
					content: "";
					position:absolute;
					width: 3.4375em;
					height:0;
					top: calc(var(--padding) * -1);
					right: 3em;
					border-top: var(--border-line);
				}

				.big-title::after{
					content: "";
					position:absolute;
					width: 3.4375em;
					height:0;
					border-top: var(--border-line);
					bottom: calc(var(--padding) * -1);
					right: var(--padding);
				}

				@media screen and (max-width: 61.875em){
					.big-title{
						font-size: 2.5em;
						white-space: inherit;
					}
				}
		`;
	}
	connectedCallback(){
		this.text  = this.getAttribute('text');
		this.color = this.getAttribute('color');
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${this.styles}</style>
			<h1 class="big-title">${this.text}</h1>
		`;
	}
}
customElements.define('big-title-wc', BigTitleWC);