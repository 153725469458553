import '../components/main-menu-wc.js';
import '../components/footer-wc.js';
import '../components/quote-generator-wc.js';
import '../components/big-title-wc.js';
import '../components/title-wc.js';
class MainView extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			.section{
				padding: 8em var(--padding-page);
				display:flex;
				justify-content: center;
				align-items: center;
				overflow:hidden;
				box-sizing:border-box;
			}

			.section--top{
				padding: 18em var(--padding-page);
			}
			@media screen and (max-width: 61.875em){
				.section{
					padding: 6em var(--padding-page);
				}
			}	
		
			@media screen and (max-width: 61.875em){
				.section--top{
					padding: 12em var(--padding-page);
				}
			}
				
			.section__content{
				width:100%;
				max-width: 65.625em;
				display:flex;
				gap: 4em;
				align-items: center;
				justify-content: center;
			}

			@media screen and (max-width: 61.875em){
				.section__content{
					flex-direction: column;
				}
			}

			.target{
				--size: 22em;
				position:relative;
				width: var(--size);
				height:var(--size);
				box-sizing:border-box;
			}
			@media screen and (max-width: 61.875em){
				.target{
					order:2;
				}
			}

			.target__part{
				position: absolute;
				width:var(--size);
				height:var(--size);
				top:0;
				left:0;
				box-sizing:border-box;
			}

			.target__part--arrow{
				transform-origin: top right;
				transform: translateY(10vh) translateX(-200vw) rotate(-90deg);
				box-sizing:border-box;
			}

			.target__target{
				transform: rotate(0);
				box-sizing:border-box;
			}

			@keyframes arrow {
				from {
					transform: translateY(10vh) translateX(-200vw) rotate(-70deg);
				}

				to{
					transform: translateY(0) translateX(0) rotate(0);
				}
			}

			@keyframes arrow2 {
				from {
					transform: rotate(0);
				}

				to{
					transform: rotate(30deg);
				}
			}

			@keyframes target {
				from {
					transform: rotate(0deg);
				}

				to{
					transform: rotate(30deg);
				}
			}

			.text-section{
				max-width: 37em;
			}

			.text-section__p{
				margin: 1.25em 0;
				line-height:1.35em;
				font-family: var(--font);
			}
		`;
	}
	connectedCallback(){
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${MainView.styles}</style>
			<main-menu-wc></main-menu-wc>
				<section class="section section--top">
			  	<quote-generator-wc></quote-generator-wc>
				</section>
				<main class="section aria-page-hide">

					<div class="section__content">
						<div class="target">
							<img loading="lazy" class="target__part" src="assets/img/target-bg.svg" alt="">
							<img loading="lazy" class="target__part target__target" src="assets/img/target-target.svg" alt="">
							<img loading="lazy" id="target-arrow" class="target__part target__part--arrow" src="assets/img/target-arrow.svg" alt="">
						</div>

						<div class="section__text text-section">
							<big-title-wc color="secondary" text="SugarPacket"></big-title-wc>
							<title-wc color="secondary" text="Your daily dose of motivation."></title-wc>
							<p class="text-section__p">SugarPacket is an API that generates a random motivational quote in image format every day. Customize the font family, colors, and aspect ratio to fit the look of your project.
							</p>
							<p class="text-section__p">Although I originally designed it to use it in my Notion - for my own needs - it can be used anywhere you choose!</p>
							<p class="text-section__p">Studies have shown that motivation can boost productivity, so why wait? Add SugarPacket to your dashboard today!
							</p>
							<p class="text-section__p">If you wanna know more about the project and how to use it, click on the "?" button at the top of the page.</p>
						</div>
					</div>
					
				</main>
				<footer-wc></footer-wc>
		`;
		this.targetAnimationOnScroll();
	}

	targetAnimationOnScroll(){
		document.addEventListener('scroll', ()=> {
			if(!animated) arrowAnimation();
		});
		window.addEventListener('load', arrowAnimation);

		const arrow = this.shadowRoot.getElementById('target-arrow');
		const target = this.shadowRoot.querySelector('.target__target');
		let animated = false;

		function arrowAnimation(){
			const top = target.getBoundingClientRect().top;

			if((  top < ((window.innerHeight/3)*2)) ){
				animated = true;
				arrow.style.animation = 'arrow 0.6s ease-out forwards';
				setTimeout(() => {
					arrow.style.transformOrigin = '50% 50%';
					arrow.style.animation = 'arrow2 0.3s ease forwards';
					target.style.animation = 'target 0.3s ease-out forwards';
				}, 550);
			}
			
		}
	}
}
customElements.define('main-view', MainView);