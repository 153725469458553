import '../components/color-input-wc.js';
import '../components/select-input-wc.js';
import '../components/alert-wc.js';
import { showAlert } from '../services/events-service.js';
import { getFonts, getIMGAlt, imgEndpoint } from '../services/data-service.js';
class QuoteGeneratorWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			.quote-generator{
				--border-radius: 1.5em;
				--input-height: 2.5em;
				--border-size: .1875em;
				--input-border: var(--border-size) solid var(--black-color);
				--input-border-radius: 1em;
				border: .1875em solid var(--secondary-color);
				background-color: var(--primary-color);
				box-shadow: var(--hard-shadow);
				max-width:45em;
				border-radius: var(--border-radius);
				min-height: 10em;
				position:relative;
				display: flex;
				flex-direction:column;
			}
			.quote-generator__controls{
				display:flex;
				gap:0.7em;
				background-color: var(--secondary-color);
				border-radius: calc(var(--border-radius) - .1875em) calc(var(--border-radius) - .1875em) 0 0;
				padding:1.1em;
				align-items:center;
				flex-wrap: wrap;
				justify-content: center;

			}
			.quote-generator__controls-groups{
				display:flex;
				gap:1em;
			}

			.quote-generator__img{
				pointer-events: none;
				border-radius: 0 0 calc(var(--border-radius) - .1875em) calc(var(--border-radius) - .1875em);
				overflow: hidden;
				width: 100%;
				height: auto;
			}
			.quote-generator__img--wide-landscape{
				aspect-ratio: 100 / 21;
			}
			.quote-generator__img--square{
				aspect-ratio: 1 / 1;
			}
			.quote-generator__img--portrait{
				aspect-ratio: 9 / 16;
			}
			.quote-generator__img--landscape{
				aspect-ratio: 16 / 9;
			}


			.quote-generator__copy{
				position:absolute;
				bottom:1em;
				left:1em;
			}

			.quote-generator--clone{
				position:absolute;
				top: calc(100% + .375em);
				left:0;
				transform-origin: 50% 50%;
				transform: scaleY(-1);
				mask-image: linear-gradient(0deg, rgba(255,255,255,1) 0%, transparent 12em);
				opacity:0.5;
				width: 100%;
			}

			.copy-button{
				position: absolute;
				bottom: 1em;
				left: 1em;
			}

			
		`;
	}
	async connectedCallback(){
		this.quote = {
			'aspect-ratio': 'landscape',
			'bg-color': 'F4687B',
			'text-color': '030203',
			'dec-color': 'FFFAB8',
			'quote-font': 'Pacifico-Regular',
			'author-font': 'Montserrat-Medium',
			'lang': 'en'
		};
		this.langs = ['en', 'es'];
		this.aspectsRatios = ['wide-landscape', 'landscape', 'square', 'portrait'];
		this.imageAlt = await getIMGAlt();
		//TODO testear a ver si se ve correctamente
		// if (window.matchMedia('(orientation: portrait)').matches) {
		// 	this.quote['aspect-ratio'] = 'square';
		// }

		const fonts        = await getFonts();
		this.fontsString   = fonts.toString();

		this.render();
		window.addEventListener('button-clicked', this.handleButtonClicked);
		window.addEventListener('select-changed', this.handleSelectChanged);
		window.addEventListener('color-changed', this.handleSelectChanged);
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${QuoteGeneratorWC.styles}</style>
			<div class="section section--top aria-page-hide">
				<div class="quote-generator">
					<div class="quote-generator__controls">
						<select-input-wc input-id="quote-font" input-label="Quote font-family" input-options="${this.fontsString}" input-selected="${this.quote['quote-font']}"></select-input-wc>
						<select-input-wc input-id="author-font" input-label="Author font-family" input-options="${this.fontsString}" input-selected="${this.quote['author-font']}"></select-input-wc>
						<select-input-wc input-id="aspect-ratio" input-label="Aspect ratio" size="sm" input-options="${this.aspectsRatios}" input-selected="${this.quote['aspect-ratio']}"></select-input-wc>
						<select-input-wc input-id="lang" input-label="Language" size="sm" input-options="${this.langs}" input-selected="${this.quote['lang']}"></select-input-wc>
						<color-input-wc input-id="dec-color" input-label="Elements color" input-selected="${this.quote['dec-color']}"></color-input-wc>
						<color-input-wc input-id="text-color" input-label="Text color" input-selected="${this.quote['text-color']}"></color-input-wc>
						<color-input-wc input-id="bg-color" input-label="Background color" input-selected="${this.quote['bg-color']}"></color-input-wc>
					</div>
					<picture>
						<source media="(max-width: 37.5em)" srcset="api/v1/daily-quote.jpg?scale=0.4 600w"/>
						<source media="(max-width: 25em)" srcset="api/v1/daily-quote.jpg?scale=0.266 400w"/>
						<img fetchpriority="high" alt="${this.imageAlt}" width="720" height="405" src="api/v1/daily-quote.jpg?scale=0.48" class="quote-generator__img quote-generator__img--landscape"/>
					</picture>

					<button-wc class="copy-button" icon="copy" text="Copy url" color="secondary" size="md" action="copy-url"></button-wc>
					
					<div class="quote-generator quote-generator--clone" aria-hidden="true">
						<picture>
							<source media="(max-width: 37.5em)" srcset="api/v1/daily-quote.jpg?scale=0.4 600w"/>
							<source media="(max-width: 25em)" srcset="api/v1/daily-quote.jpg?scale=0.266 400w"/>
							<img fetchpriority="high" alt="${this.imageAlt}" width="720" height="405" src="api/v1/daily-quote.jpg?scale=0.48" class="quote-generator__img quote-generator__img--landscape"/>
						</picture>
					</div>
				</div>
				
			</div>
		`;
	}
	// render() {
	// 	this.shadowRoot.innerHTML = /* html */`
	// 		<style>${QuoteGeneratorWC.styles}</style>
	// 		<div class="section section--top aria-page-hide">
	// 			<div class="quote-generator">
	// 				<div class="quote-generator__controls">
	// 					<select-input-wc input-id="quote-font" input-label="Quote font-family" input-options="${this.fontsString}" input-selected="${this.quote['quote-font']}"></select-input-wc>
	// 					<select-input-wc input-id="author-font" input-label="Author font-family" input-options="${this.fontsString}" input-selected="${this.quote['author-font']}"></select-input-wc>
	// 					<select-input-wc input-id="aspect-ratio" input-label="Aspect ratio" size="sm" input-options="${this.aspectsRatios}" input-selected="${this.quote['aspect-ratio']}"></select-input-wc>
	// 					<select-input-wc input-id="lang" input-label="Language" size="sm" input-options="${this.langs}" input-selected="${this.quote['lang']}"></select-input-wc>
	// 					<color-input-wc input-id="dec-color" input-label="Elements color" input-selected="${this.quote['dec-color']}"></color-input-wc>
	// 					<color-input-wc input-id="text-color" input-label="Text color" input-selected="${this.quote['text-color']}"></color-input-wc>
	// 					<color-input-wc input-id="bg-color" input-label="Background color" input-selected="${this.quote['bg-color']}"></color-input-wc>
	// 				</div>
	// 				<picture>
	// 					<source media="(max-width: 37.5em)" srcset="api/v1/daily-quote.jpg?scale=0.4 600w"/>
	// 					<source media="(max-width: 25em)" srcset="api/v1/daily-quote.jpg?scale=0.266 400w"/>
	// 					<img fetchpriority="high" alt="${this.imageAlt}" width="720" height="405" src="api/v1/daily-quote.jpg?scale=0.48" class="quote-generator__img quote-generator__img--landscape"/>
	// 				</picture>

	// 				 <img fetchpriority="high" alt="${this.imageAlt}" width="720" height="405" src="api/v1/daily-quote.jpg?scale=0.48" class="quote-generator__img quote-generator__img--landscape"/>
	// 				<button-wc class="copy-button" icon="copy" text="Copy url" color="secondary" size="md" action="copy-url"></button-wc>
					
	// 				<div class="quote-generator quote-generator--clone" aria-hidden="true">
	// 					<img fetchpriority="high" alt="${this.imageAlt}" width="720" height="405" src="api/v1/daily-quote.jpg?scale=0.48" class="quote-generator__img"/>
	// 				</div>
	// 			</div>
				
	// 		</div>
	// 	`;
	// }

	handleButtonClicked = (e) => {
		const action = e.detail.action;
		if(action == 'copy-url') this.copyURL();
	};

	handleSelectChanged = (e) => {
		const newValue   = e.detail.selection;
		const propertyId = e.detail['select-id'];
		this.quote[propertyId] = newValue;
		this.refreshImg();
		if(propertyId == 'aspect-ratio') this.setAspectRatioStyles(newValue);
		
	};

	copyURL(){
		const base = document.baseURI;
		navigator.clipboard.writeText(base + this.getUri());

		showAlert('Link copied to clipboard!', 'check');
	}

	refreshImg(){
		const imgEls = this.shadowRoot.querySelectorAll('.quote-generator__img');
		imgEls.forEach(img =>{
			// img.setAttribute('alt', alt.alt);
			img.src = this.getUri();
		});
	}

	getUri(){
		return `${imgEndpoint}?aspect-ratio=${this.quote['aspect-ratio']}&bg-color=${this.quote['bg-color']}&text-color=${this.quote['text-color']}&dec-color=${this.quote['dec-color']}&quote-font=${this.quote['quote-font']}&author-font=${this.quote['author-font']}&lang=${this.quote['lang']}`;
	}

	setAspectRatioStyles(aspect){
		//TODO Hacer un loader visual para cuando esté cargando
		const imgEls = this.shadowRoot.querySelectorAll('.quote-generator__img');
		imgEls.forEach( container =>{
			if(container.classList.contains('quote-generator__img--landscape')) container.classList.remove('quote-generator__img--landscape');
			if(container.classList.contains('quote-generator__img--wide-landscape')) container.classList.remove('quote-generator__img--wide-landscape');
			if(container.classList.contains('quote-generator__img--portrait')) container.classList.remove('quote-generator__img--portrait');
			if(container.classList.contains('quote-generator__img--square')) container.classList.remove('quote-generator__img--square');
			container.classList.add(`quote-generator__img--${aspect}`);
		});
	}

}
customElements.define('quote-generator-wc', QuoteGeneratorWC);