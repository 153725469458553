import './views/main-view.js';
import './views/error-view.js';
import './components/modal-wc.js';

// import './style.scss';
import(/* webpackChunkName: "styles" */ './style.scss');
import { showModal } from './services/events-service.js';

window.addEventListener('button-clicked', checkButtonClicked);

function checkButtonClicked(e){
	const action = e.detail.action;

	if(action == 'open-about-modal') showModal(infoModal); 

}

const infoModal = `<div class="modal__column modal__column--title">
							<big-title-wc color="primary" text="About SugarPacket"></big-title-wc>
						</div>
						
						<div class="modal__column modal__column--a ">
							<title-wc color="primary" text="How it all started"></title-wc>
							<p class="text-section__p">At the end of 2022, I was tinkering with my Notion Dashboard, and it just felt like it was missing something. I mean, I use it every day, so I wanted it to look cool and keep me motivated.</p>
							<p class="text-section__p">At first, I thought about slapping a famous quote at the top, but let's be real - that would get old fast. That's when it hit me: why not have a brand new image with a fresh quote every day that I could customize to fit my dashboard? Sure, I was hesitant to
				take on yet another project, but that's how SugarPacket was born.</p>
						</div>

						<div class="modal__column modal__column--b ">
							<title-wc color="primary" text="Why the name SugarPacket?"></title-wc>
							<p class="text-section__p">In Spain, when you order coffee, it is usually served with a packet of sugar that has a famous quote on it. That's where I got inspiration for the name.</p>
						</div>

						<div class="modal__column modal__column--c ">
							<title-wc color="primary" text="Why choose SugarPacket?"></title-wc>
							<p class="text-section__p">Get a unique, inspiring quote every day for your Notion, website, or personal project. SugarPacket provides a custom image with a brand new, inspiring quote that changes every midnight! And you won't get the same quote for a whole year - that's a promise!</p>
						</div>

						<div class="modal__column modal__column--d ">
							<title-wc color="primary" text="How does it work?"></title-wc>
							<p class="text-section__p">Go to the homepage and select your font family, language, decoration color, text color, and background color. Then, click on the copy button. Now, paste the URL wherever you need it - et voilà! You’ll have a different motivational quote every day.</p>
						</div>
						<iframe class="modal__column modal__column--e video" width="560" height="315" loading="lazy" src="https://www.youtube.com/embed/GVpTVtfScgw" title="Video: How to add Sugarpacket quote to Notion" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
					`;

//? He tenido que cargar así las tipografías, si lo hacía con @font-face no se cargaban
// const pacificoFont = new FontFace('Pacifico Regular', 'url(./assets/fonts/Pacifico-Regular.woff2)', {
// 	style: 'normal',
// 	weight: '400',
// });
// const montserratMediumFont = new FontFace('Montserrat Medium', 'url(./assets/fonts/Montserrat-Medium.woff2)', {
// 	style: 'normal',
// 	weight: '500',
// });

// pacificoFont.load().then(() => {},(err) => { console.error(`No se pudo cargar la fuente: ${err}`); },);
// montserratMediumFont.load().then(() => {},(err) => { console.error(`No se pudo cargar la fuente: ${err}`); },);

// document.fonts.add(pacificoFont);
// document.fonts.add(montserratMediumFont);
