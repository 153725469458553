import { hideTooltip, showTooltip } from '../services/events-service.js';
import { getIcon } from '../services/icon-service.js';
import { getNewEl } from '../services/ui-service.js';
import './tooltip-wc.js';
class ColorInputWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	get styles() {
		return /* css */ `
			:host{
				--select-width: 100%;
				
				flex-basis: min-content;
				flex-grow: 1;
				position:relative;
				min-width:${this.size == 'sm' ? '5.5em' : '7.8em'};
				display:block;
				box-sizing: border-box;
			}
			.arrow{
				width:0.7em;
				height: var(--input-height);
				position:absolute;
				right:0.5em;
				top:0;
				pointer-events: none;
				fill: var(--black-color);
			}
			.select{
				width:var(--select-width);
				height: 3.125em;
				border-radius: 1.25em; 
				border: 0.234375em solid var(--black-color);
				z-index: 1;
				transition: border 0.3s ease;
				box-shadow: 0 0 5px rgba(3,2,3,0);
				padding: 0.625em 0.875em 0.625em 0.625em; 
				font-family: var(--font);
				font-size:0.8em;
				text-transform: capitalize;
				text-overflow: ellipsis;
				appearance: none;
    		background-color: var(--secondary-color);
				outline:none;
			}
			:host(:hover) .select, :host(:focus-within) .select{
				border: 0.234375em solid var(--primary-color);
				color: var(--primary-color);
			}
			:host(:hover) .arrow,  :host(:focus-within) .arrow{
				fill: var(--primary-color);
			}
		`;
	}
	connectedCallback(){
		this.inputId = this.getAttribute('input-id');
		this.inputLabel   = this.getAttribute('input-label');
		this.size = this.getAttribute('size');
		this.options = this.getAttribute('input-options');
		this.selected = this.getAttribute('input-selected');

		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${this.styles}</style>
			<tooltip-wc tooltip-name="tooltip-${this.inputId}" tooltip-content="${this.inputLabel}" size="sm" bg-color="primary" aria-hidden="true"></tooltip-wc>
			<select class="select" name="${this.inputId}" id="${this.inputId}" aria-label="${this.inputLabel}"></select>
			${getIcon('arrow', 'arrow')}
		`;
		const dic = {
			'es': 'Español',
			'en': 'English',
		};
		const input = this.shadowRoot.querySelector('select');
		const options = this.options.split(',').map((option)=> option.trim());
		options.forEach(option => {
			const optionEl = getNewEl('option', { value: option });
			optionEl.innerText = dic[option] || option;
			if(option == this.selected) optionEl.setAttribute('selected', true);
			input.appendChild(optionEl);
		});

		input.addEventListener('mouseenter', () => showTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('focus', ()=> showTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('mouseout', ()=>hideTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('blur', ()=>hideTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('change', (e)=> {
			// console.log('selection: ',e.target.value);
			let customEvent = new CustomEvent('select-changed', {
				detail: { selection: e.target.value , 'select-id': this.inputId }
			});
			window.dispatchEvent(customEvent);
		});
	}
}
customElements.define('select-input-wc', ColorInputWC);