const { getIcon } = require("../services/icon-service");

class TitleWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	get styles() {
		return /* css */ `
			:host{
				display: flex;
    		align-items: center;
			}

			.standard-title{
				font-family: var(--titles-font);
				font-size: 2em;
				position:relative;
				z-index:1;
				margin-bottom: 1em;
				line-height: 1.1em;
			}

			.icon{
				width: 0.8em; 
				height: 0.8em;
				fill: var(--${this.color}-color);
			}

			.standard-title::after{
				content: "";
				position:absolute;
				width:6em;
				height:0.5em;
				bottom: -0.1em;
				left:1em;
				z-index: -1;
				border-radius: 0.25em;
				background-color: var(--${this.color}-color);
			}
					
			@media screen and (max-width: 61.875em){
				.standard-title{
					font-size: 1.5em;
				}
			}
		`;
	}
	connectedCallback(){
		this.text  = this.getAttribute('text');
		this.color = this.getAttribute('color');
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${this.styles}</style>
			${getIcon('bullet', 'icon')}
			<h2 class="standard-title">${this.text}</h2>
		`;
	}
}
customElements.define('title-wc', TitleWC);