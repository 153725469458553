export function showTooltip(tooltipName){
	let customEvent = new CustomEvent('show-tooltip', {
		detail: { 'tooltip-name': tooltipName }
	});
	window.dispatchEvent(customEvent);
}
export function hideTooltip(tooltipName){
	let customEvent = new CustomEvent('hide-tooltip', {
		detail: { 'tooltip-name': tooltipName }
	});
	window.dispatchEvent(customEvent);
}

export function showModal(content, cancel_action, confirm_action){
	let customEvent = new CustomEvent('show-modal', {
		detail: { content, cancel_action, confirm_action }
	});
	window.dispatchEvent(customEvent);
}

export function closeModal(){
	let customEvent = new CustomEvent('close-modal');
	window.dispatchEvent(customEvent);
}

export function showAlert(content, icon){
	let customEvent = new CustomEvent('show-alert', {
		detail: { content, icon }
	});
	window.dispatchEvent(customEvent);
}