class TextLinkWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: "open" });
	}
	static get styles() {
		return /* css */ `
			.link{
				font-family: var(--titles-font);
				margin-left:0.3em;
				position: relative;
				transition: all 0.3s ease;
				transform: translateY(0);
				cursor: pointer;
				font-size: 1em;
				text-decoration: none;
				color: var(--black-color);
				display: block;
			}
			.link:hover{
				color: var(--secondary-color);
				transform: translateY(-0.5em);
			}

			.link:after {
				content: "";
				position: absolute;
				width: 100%;
				border-bottom: 2px dotted var(--secondary-color);
				bottom: 0;
				left: 0;
				pointer-events: none;
			}
		`;
	}
	connectedCallback(){
		this.text   = this.getAttribute('text');
		this.link   = this.getAttribute('link');
		this.target = this.getAttribute('target');
		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${TextLinkWC.styles}</style>
			<a href="${this.link}" target="_blank" class="link" lang="es">${this.text}</a>
		`;
	}
}
customElements.define("text-link-wc", TextLinkWC);