import { hideTooltip, showTooltip } from '../services/events-service.js';
import './tooltip-wc.js';
class ColorInputWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			:host{
				width: var(--input-height);
				min-width: var(--input-height);
				height: var(--input-height);
				min-height: var(--input-height);
				max-height: var(--input-height);
				border-radius: var(--input-border-radius);
				transition: border 0.3s ease;
				box-shadow: 0 0 5px rgba(3,2,3,0);
				flex-grow: 0;
				flex-basis: auto;
				position:relative;
				box-sizing: border-box;
			}
			@media screen and (max-width: 46.875em){
				:host{
					flex-grow: 1;
				}
			}

			.wrapper{
				overflow:hidden;
				border-radius: var(--input-border-radius);
				border: var(--input-border);
				width:100%;
				height:100%;
				display:flex;
				align-items: center;
				justify-content: center;
				box-sizing: border-box;
			}

			.input{
				min-width: 150%;
				min-height: 150%;
				box-sizing: border-box;
			}

			:host(:hover) .wrapper, :host(:focus-within) .wrapper{
				border: 0.234375em solid var(--primary-color);
			}
		`;
	}
	connectedCallback(){
		this.inputId = this.getAttribute('input-id');
		this.inputLabel   = this.getAttribute('input-label');
		this.selected = this.getAttribute('input-selected');

		this.render();
	}
	render() {
		this.shadowRoot.innerHTML = /* html */`
			<style>${ColorInputWC.styles}</style>
			<tooltip-wc tooltip-name="tooltip-${this.inputId}" tooltip-content="${this.inputLabel}" aria-hidden="true" size="sm" bg-color="primary"></tooltip-wc>
			<div class="wrapper">
				<input class="input" type="color" name="${this.inputId}" id="${this.inputId}" aria-label="${this.inputLabel}">
			</div>
		`;
		const input = this.shadowRoot.querySelector('input');
		input.setAttribute('value', `#${this.selected}`);
		input.addEventListener('mouseenter', () => showTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('focus', ()=> showTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('mouseout', ()=>hideTooltip(`tooltip-${this.inputId}`));
		input.addEventListener('blur', ()=>hideTooltip(`tooltip-${this.inputId}`));

		input.addEventListener('change', (e)=> {
			// console.log('selection: ',e.target.value);
			const selectedColor = e.target.value.split('#')[1];
			let customEvent = new CustomEvent('color-changed', {
				detail: { selection: selectedColor , 'select-id': this.inputId }
			});
			window.dispatchEvent(customEvent);
		});
		// const input = color_picker.querySelector('.input-color__input');
		// input.addEventListener('change', (e)=> { 
		// 	const color = e.target.value.split('#')[1];
		// 	const type  = e.target.id;
		// 	quote[type] = color;
		// 	loadIMG();
		// })
		// input.setAttribute('value', `#${quote[input.id]}`);
	}
}
customElements.define('color-input-wc', ColorInputWC);