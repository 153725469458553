import { getIcon } from './icon-service';

export function getTooltipContent(tooltipName){
	switch (tooltipName) {
	case 'tooltip-made-with': return /* HTML */ `
		<ul class="tooltip__list">
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}Uncountable hours of learning</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}NodeJS, Fastify, node-canvas, Web Components, HTML, and CSS.</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}Illustrator and Figma</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}Over 50 hours of thinking, designing, and developing.</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}$1.16 for the domain.</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}About $14 for the hosting</li>
			<li class="tooltip__el">${getIcon('bullet', 'bullet-icon')}And of course: with <img alt="love" loading="lazy" class="tooltip__icon" src="assets/img/heart-icon.svg" title="Love"></li>
		</ul>`;

	default: return 'Tooltip content not set';
	}
}

export function getNewEl(elType, elAttributes){
	const newEl = document.createElement(elType);
	if(elAttributes == null) return newEl;
	for (const [key, value] of Object.entries(elAttributes)) {
		newEl.setAttribute(key, value);
	}
	return newEl;
}