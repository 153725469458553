const apiEntry       = 'api/v1/';
export const imgEndpoint    = `${apiEntry}daily-quote.jpg`;
export const errorImgEndpoint    = `${apiEntry}error-quote.jpg`;
const imgAltEndpoint = `${apiEntry}img-alt`;
const fontsEndpoint  = `${apiEntry}fonts`;


export async function getFonts(){
	return await fetch(fontsEndpoint)                  
		.then(response => {
			if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
			return response.json();
		})
		.then(data => {
			const fonts =  [...data];
			// console.log('fonts: ', fonts);
			return fonts;
		})
		.catch(err => console.error('ERROR!!!!', err)); 
}
export async function getIMGAlt(){
	return await fetch(imgAltEndpoint)                  
		.then(response => {
			if (!response.ok) throw new Error(`${response.status}: ${response.statusText}`);
			return response.json();
		})
		.then(data => {
			// const fonts =  [...data];
			// console.log('fonts: ', fonts);
			return data.alt;
		})
		.catch(err => console.error('ERROR!!!!', err)); 
}
