import { getIcon } from '../services/icon-service';

class AlertWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	static get styles() {
		return /* css */ `
			:host{
				--icon-size: 1.5em;
				position:fixed;
				top: -6em;
				left:50%;
				transform: translateX(-50%);
				background-color: var(--primary-color);
				box-shadow: var(--hard-shadow);
				border-radius: 1em;
				z-index:3;
				padding: 0 1em !important;
				transition: top 0.3s ease;
				display:flex;
				align-items: center;
			}

			.text{
				position:relative;
				white-space: nowrap;
				font-family: var(--font);
			}
				
			:host(.show){
				top: 1em;
			}

			@media screen and (max-width: 48.75em){
				:host{
					background-color: var(--secondary-color);
				}

				.text{
					&::before{

						background-image: url('assets/img/check-icon.svg');
					}
				}

				:host(.show){
					top: 10em;
				}
				
			}

			@media screen and (max-width: 35.625em){
				:host(.show){
					top: 7em;
				}
			}

			.icon{
				width: auto;
				height: var(--icon-size);

			}
			.icon__line{
				fill: var(--black-color);
			}
			.icon__bg{
				fill: var(--secondary-color);
			}
		`;
	}
	connectedCallback(){
		this.render();
		window.addEventListener('show-alert', this.showAlert);
		// function showAlert(text){
		// 	const alert = document.createElement('div');
		// 	const alert_text = document.createElement('p');
		// 	alert.classList.add('alert');
		// 	alert_text.classList.add('alert__text');
		// 	alert_text.innerText = text;
		// 	alert.appendChild(alert_text);

		// 	document.body.appendChild(alert);
			
		// 	setTimeout(() => {
		// 		alert.classList.add('alert--show')
		// 		setTimeout(() => {
		// 			alert.classList.remove('alert--show')
		// 			setTimeout(() => {
		// 				alert.remove()
		// 			}, 300);
		// 		}, 2000);
		// 	}, 300);
			
		// }
	}
	render(text, icon) {
		this.shadowRoot.innerHTML = /* html */`
			<style>${AlertWC.styles}</style>
			${getIcon(icon, 'icon')}
			<p class="text">${text}</p>
		`;
	}

	showAlert = (e) =>{
		let text= e.detail.content;
		let icon= e.detail.icon;
		this.render(text, icon);

		setTimeout(() => {
			this.classList.add('show');
			setTimeout(() => {
				this.classList.remove('show');
			}, 2000);
		}, 300);
	};
}
customElements.define('alert-wc', AlertWC);