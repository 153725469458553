import { isMailto, isURL, isTooltip } from '../services/brain-service.js';
import { hideTooltip, showTooltip } from '../services/events-service.js';
import { getIcon } from '../services/icon-service.js';

//? This WC creates a button with an icon
//* Dependencies:
//? 	- icon-service.js
//* Atributtes: 
//? 	- icon   : the name of the icon from the icon-service
//? 	- text   : it will be shown on mouseover (title attribute)
//? 	- color  : 2 options (primary (default), secondary)
//? 	- size   : 2 options (xs, sm, md (default))
//? 	- action : button action, must be unique. It tells the listener wich button was clicked
//* Events:
//? 	- 'button-clicked': it tells the listener when the button was clicked.
//?   	use: 
//? 			window.addEventListener('button-clicked', evt => { 		
//?					const action = evt.detail.action;
//?				});

class ButtonWC extends HTMLElement{
	constructor(){
		super();
		this.attachShadow({ mode: 'open' });
	}
	get styles() {
		return /* css */ `
			:host{
				display: block;
			}

			.icon-button{
				position:relative;
				cursor:pointer;
				font-family: inherit;
				font-size: inherit;
				hyphens: auto;
				background-color: transparent;
				color:inherit;
				display: block;
				appearance: none;
				outline:none;
				border:none;
				padding: 0;
			}

			.sm{
				width: 2.5em;
			}

			.md{
				width: 3.5em;
			}
				
			.icon-button__svg{
				pointer-events: none;
			}

			.icon-button__ring{
				fill: var(--black-color);
				transition: clip-path 0.1s ease-out;
				clip-path:   polygon(22.47% 14.06%, 15% 12%, 3.72% 24.16%, -1.89% 44.82%, -2px 70.37%, 12.86% 90.65%, 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%, 20.21% 79.51%, 11.61% 64.08%, 8.14% 44.04%, 13.44% 26.31%);
			}

			.icon-button__bg{
				transform: scale(1);
				transition: transform 0.2s ease-out;
				transform-origin: center center;
			}

			.icon-button__text{
				position:absolute;
				font-size: 0.7em;
				white-space: nowrap;
				bottom:-1em;
				left: 50%;
				transform: translateX(-50%);
				font-family: var(--font);
				opacity:0;
				transition: opacity 0.3s 0.1s ease-out;
				pointer-events:none;
			}

			.icon-button:hover .icon-button__bg, .icon-button:focus .icon-button__bg {
				transform: scale(0);
			}
			.icon-button:hover .icon-button__text, .icon-button:focus .icon-button__text {
				opacity: 1;
			}
			.icon-button:hover .icon, .icon-button:focus .icon {
				transform: scale(1.1);
			}

			.icon-button--primary .icon-button__bg, .icon-button--primary .icon__bg--clip{
				fill: var(--primary-color);
			}

			.icon-button--primary .icon__bg{
				fill: var(--secondary-color);
			}			
			.icon-button--secondary .icon-button__bg, .icon-button--secondary .icon__bg--clip{
				fill: var(--secondary-color);
			}

			.icon-button--secondary .icon__bg{
				fill: var(--primary-color);
			}			

			.icon{
				pointer-events: none;
				transform: scale(1);
				transition: transform 0.2s 0.1s ease-in;
				position:absolute;
				padding: .4em;
			}
			.icon__line{
				fill: var(--black-color);
			}

			.icon__bg--clip{
				clip-path: ellipse(54px 17px at -10px 97px);
			}

		`;
	}
	connectedCallback(){
		this.icon     = this.getAttribute('icon');
		this.text     = this.getAttribute('text');
		this.color    = this.getAttribute('color') || 'primary';
		this.size     = this.getAttribute('size') || 'md';
		this.action   = this.getAttribute('action');
		this.textIsVisible = (this.getAttribute('text-visible') == 'true');
		this.width    = this.getAttribute('width');
		
		this.render();
	}
	render() {
		const isLink    = isURL(this.action) || isMailto(this.action);
		const isTt = isTooltip(this.action);
		const type  = isLink ? 'a' : 'button';
		const isSamePage = this.action.startsWith('/') || this.action.startsWith(window.document.location.origin); 
		const target  = isLink && isSamePage ? '_self' : '_blank';
		this.shadowRoot.innerHTML = /* html */`
			<style>${this.styles}</style>

			<${type} ${isLink ? `href="${this.action}" target="${target}"` : ''}  class="icon-button icon-button--${this.color} ${this.size}">
				${getIcon(this.icon, 'icon')}
				<svg class="icon-button__svg" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
					<path class="icon-button__bg" d="m6.98,7.55c-2.46,2.94-3.74,6.07-3.66,9.54.04,1.71.28,3.44.94,5.02,1.72,4.16,6.26,6.8,10.76,6.84,4.5.04,12.8-2.81,13.97-10.89.85-5.81-3.22-14.44-9.74-14.76-5.15-.26-10.33,1.95-12.28,4.25"/>
					<path  class="icon-button__ring" d="m5.36,5.53C.08,9.15-.07,18.34,3.84,22.99c3.65,4.34,9.7,5.24,14.88,3.54s9.17-5.31,9.94-10.66-2.05-11.5-7.14-13.54c-3.2-1.28-7.32-.83-10.36.7-3.62,1.83-5.49,5.5-7.16,9-.38.79,1.37.63,1.63.07,1.3-2.73,2.67-5.71,5.15-7.59s6.08-2.37,8.94-1.46c4.65,1.48,7.35,6.88,7.31,11.52-.09,9.77-12.75,15.23-20.16,9.1-2.77-2.29-3.87-5.62-3.88-9.14,0-3.08.79-6.6,3.48-8.44.97-.67-.51-.98-1.1-.58h0Z"/>
				</svg>
				<span class="icon-button__text">${this.text}</span>
			</${type}>
		`;

		
		//*Click event
		if(!isLink){
			const button = this.shadowRoot.querySelector('button');

			if(isTt){
				button.addEventListener('mouseenter', () => showTooltip(this.action));
				button.addEventListener('focus', ()=> showTooltip(this.action));
				button.addEventListener('mouseout', ()=>hideTooltip(this.action));
				button.addEventListener('blur', ()=>hideTooltip(this.action));
			} else {
				button.addEventListener('click', ()=>{
					this.dispatchEvent(new CustomEvent('button-clicked', { 
						composed: true, 
						bubbles: true,
						detail: { action: this.action } 
					}));
				});
			}
			
		} 
		this.buttonAnimation();
		
	}

	buttonAnimation(){

		const iconButtonEl = this.shadowRoot.querySelector('.icon-button');
		const ANIMATION_DURATION = 300;
		const ANIMATION_DELAY = 100;

		const ringAnimationFrames = [
			{  clipPath:   'polygon(22.47% 14.06%, 15% 12%, 3.72% 24.16%, -1.89% 44.82%, -2px 70.37%, 12.86% 90.65%, 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%, 20.21% 79.51%, 11.61% 64.08%, 8.14% 44.04%, 13.44% 26.31%)'},
			{  clipPath:   'polygon( 3.72% 24.16%, -1.89% 44.82%, -2px 70.37%, 12.86% 90.65%, 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%, 20.21% 79.51%, 11.61% 64.08%, 8.14% 44.04%, 13.44% 26.31%)'},
			{  clipPath:   'polygon( -2px 70.37%, 12.86% 90.65%, 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%, 20.21% 79.51%, 11.61% 64.08%)'},
			{  clipPath:   'polygon( 12.86% 90.65%, 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%, 20.21% 79.51%)'},
			{  clipPath:   'polygon( 32.72% 101.16%, 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%, 35.85% 89.37%)'},
			{  clipPath:   'polygon( 63.5% 102.32%, 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%, 61.24% 89.13%)'},
			{  clipPath:   'polygon( 90.97% 84.96%, 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%, 82.28% 72.23%)'},
			{  clipPath:   'polygon( 106.14% 43.88%, 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%, 90.1% 41.48%)'},
			{  clipPath:   'polygon( 95.7% 12.47%, 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%, 81.42% 20.86%)'},
			{  clipPath:   'polygon( 75.59% -7px, 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%, 66.95% 10.75%)'},
			{  clipPath:   'polygon( 53.23% -14px, 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%, 52.59% 9.26%)'},
			{  clipPath:   'polygon( 33.68% 0.66%, 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%, 40.16% 13.83%)'},
			{  clipPath:   'polygon( 20.06% 18.3%, 8.11% 43.16%, 16.9% 45.84%, 28.29% 23.55%)'},
			{  clipPath:   'polygon( 8.11% 43.16%, 16.9% 45.84%)'},
		];

		const bgAnimationFrames = [
			{  clipPath:  'ellipse(54px 17px at -10px 97px)'},
			{  clipPath:  'ellipse(54px 62px at -2px 53px)'},
		];

		const elEventsToListen = ['mouseover', 'mouseout', 'focus', 'blur'];
		
		elEventsToListen.forEach( evt => 
			iconButtonEl.addEventListener(evt, (e)=> animateEl(e, iconButtonEl), false)
		);

		function setElAnimation(el, frames, duration, delay, fillMode, direction){
			const animationKeyframes = new KeyframeEffect(el, frames, { duration: duration, delay: delay, easing: 'ease-out', direction: direction, fill: fillMode } );
			return new Animation(animationKeyframes, document.timeline);
		}

		function animateEl(e, el){
			//? Don't animate if event is hover or mouseaout and is already in focus
			//? Or if event is focus or blur and is already in hover
			const isOpositeEvent = ((e.type == 'mouseover' || e.type == 'mouseout') && el.matches(':focus')) || ((e.type == 'focus' || e.type == 'blur') && el.matches(':hover'));
			if (isOpositeEvent) return;
			const fillMode = (e.type == 'mouseover' || e.type == 'focus') ? 'normal' : 'reverse';
			const ringEl = el.querySelector('.icon-button__ring');
			const bgEl = el.querySelector('.icon__bg--clip');
			setElAnimation(ringEl, ringAnimationFrames, ANIMATION_DURATION, 0, 'forwards', fillMode).play();
			setElAnimation(bgEl, bgAnimationFrames, ANIMATION_DURATION, ANIMATION_DELAY, 'forwards', fillMode).play();
		}

	}
}
customElements.define('button-wc', ButtonWC);